import { IPageModel } from "@package/store";

export default {
  namespace: "dashboard",
  actionType: {
    Post: {
      list: "/backapi/dashboard/list",
    },
  },
} as IPageModel;
