import { ResponseCode } from "@src/global/layout";
import { useDispatchAsync } from "@src/javascripts/common/useDispatch";
import { Badge, Button, Calendar, Space, Tag, Tooltip } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { RenderIcon } from "@package/icons";
import { useNavigate } from "react-router-dom";
import SaintModal from "./components/saintModal";
import EventModal from "@src/javascripts/pages/dailySchedule/editEvent";
import EnumScheduleItemType from "../dailySchedule/enum/EnumScheduleItemType";

const Dashboard = () => {
  const [data, setData] = useState({
    saints: [],
    schedules: [],
  });

  const saintModalRef = useRef<any>();
  const eventModalRef = useRef<any>();

  const [selectedDate, setSelectedDate] = useState(dayjs());

  const navigate = useNavigate();

  const getMonthdata = async ({ month, year }) => {
    let _res = await useDispatchAsync({
      type: "dashboard/list",
      data: {
        month: month,
        year,
      },
    });

    if ((_res.code = ResponseCode.Success)) {
      setData(_res.data);
    }
  };

  useEffect(() => {
    getMonthdata({ month: dayjs().month() + 1, year: dayjs().year() });
  }, []);

  const renderDateCell = (params) => {
    //当不属于这个月的日期时不渲染
    if (dayjs(params).month() != selectedDate.month()) return <></>;

    const date = dayjs(params).format("MM/DD");

    let _saints = [];
    let _schedules = [];

    (data?.saints || [])
      .filter((item) => item.saintDate == date)
      .forEach((item) => {
        _saints.push(
          <span key={`saint-${item.saintId}`}>
            <Badge
              status="success"
              text={
                <Tooltip title={item.saintName}>
                  <span className="text-ellipsis overflow-hidden whitespace-nowrap inline-block text-nowrap">
                    {item.saintName}
                  </span>
                </Tooltip>
              }
            />
          </span>
        );
      });

    (data?.schedules || [])
      .filter((item) => {
        return dayjs(item.scheduleDate).format("MM/DD") == date;
      })
      .forEach((item) => {
        (item?.scheduleItems || []).forEach((subItem) => {
          _schedules.push(
            <span key={`schedule-item-${subItem.scheduleItemId}`}>
              <Tag>
                {EnumScheduleItemType.find(
                  (item) => item.value == subItem.scheduleItemTitle
                )?.label || subItem.scheduleItemTitle}
              </Tag>
              <Tooltip title={subItem.scheduleItemContent}>
                <span className="text-ellipsis overflow-hidden whitespace-nowrap inline-block text-nowrap">
                  {subItem.scheduleItemContent}
                </span>
              </Tooltip>
            </span>
          );
        });
      });

    return (
      <Space.Compact size="small" direction="vertical">
        <span className="flex flex-row items-center justify-start">
          <Tooltip title="添加圣人">
            <Button
              type="link"
              size="small"
              icon={<RenderIcon type="PlusCircleOutlined" />}
              onClick={() => {
                saintModalRef.current?.setOpen?.(true);
              }}
            />
          </Tooltip>
          圣人：
        </span>
        {_saints}
        <span className="flex flex-row items-center justify-start">
          <Tooltip title="添加圣人">
            <Button
              type="link"
              size="small"
              icon={<RenderIcon type="PlusCircleOutlined" />}
              onClick={() => {
                eventModalRef.current?.setVisible?.(true);

                let _data = data?.schedules?.find((item) => {
                  return dayjs(item.scheduleDate).format("MM/DD") == date;
                });

                if (!_data)
                  eventModalRef.current?.setFieldValue(
                    "scheduleDate",
                    params.format("YYYY/MM/DD")
                  );
                else {
                  eventModalRef.current?.setFieldsValue(_data);
                }
              }}
            />
          </Tooltip>
          圣言：
        </span>
        {_schedules}
      </Space.Compact>
    );
  };

  return (
    <>
      <Calendar
        mode={"month"}
        dateCellRender={renderDateCell}
        onChange={(date) => {
          setSelectedDate(dayjs(date));
          getMonthdata({
            month: dayjs(date).month() + 1,
            year: dayjs(date).year(),
          });
        }}
      ></Calendar>
      <SaintModal
        ref={saintModalRef}
        date={selectedDate}
        refreshData={getMonthdata}
      />
      <EventModal
        ref={eventModalRef}
        date={selectedDate}
        onSave={() => {
          getMonthdata({
            month: selectedDate.month() + 1,
            year: selectedDate.year(),
          });
        }}
      />
    </>
  );
};

export default Dashboard;
