import React, { useRef } from "react";
import { RemoteTable } from "@package/table";
import {
  useDelTableRecord,
  useLoadTableData,
} from "@src/javascripts/common/useTableCallback";
// import EditSaint from "./edit";
import {
  Button,
  Divider,
  Popconfirm,
  Space,
  Tag,
  Tooltip,
  message,
} from "antd";
import { RenderIcon } from "@package/icons";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatchAsync } from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";
import { EnumParish } from "@src/javascripts/common/enums/EnumParish";
import {
  EnumNoticeStatusColor,
  EnumNoticeStatusDesc,
} from "@src/javascripts/common/enums/EnumNoticeStatus";

const isNoticeExpired = (record) => {
  return (
    dayjs(record.noticeStartDate).diff(dayjs(), "day") > 0 ||
    dayjs(record.noticeEndDate).diff(dayjs(), "day") < 0
  );
};

const Notice = () => {
  const _ref = useRef<any>();
  const [table] = RemoteTable.useTable();

  const _columns = [
    {
      dataIndex: "noticeTitle",
      title: "通知标题",
      filter: true,
    },
    {
      dataIndex: "parish",
      title: "所属教区",
      filter: true,
      render: (data) => {
        return EnumParish[data] || data;
      },
      type: "select",
      formControlProps: {
        options: Object.entries(EnumParish).map((item) => {
          return {
            value: item[0],
            label: item[1],
          };
        }),
      },
    },
    {
      dataIndex: "noticeAlways",
      title: "长期有效",
      width: 80,
      render: (data) => {
        return <>{data ? "是" : "否"}</>;
      },
    },
    {
      dataIndex: "noticeStartDate",
      title: "生效时间",
      width: 140,
      // filter: true,
      type: "date",
      render: (data) => {
        return <>{!!data ? dayjs(data).format("YYYY年MM月DD日") : "----"}</>;
      },
    },
    {
      dataIndex: "noticeEndDate",
      title: "过期时间",
      width: 140,
      // filter: true,
      type: "date",
      render: (data) => {
        return <>{!!data ? dayjs(data).format("YYYY年MM月DD日") : "----"}</>;
      },
    },
    {
      dataIndex: "noticeStatus",
      width: 80,
      title: "状态",
      type: "select",
      filter: true,
      formControlProps: {
        options: Object.entries(EnumNoticeStatusDesc).map((item) => {
          return {
            label: item[1],
            value: item[0],
          };
        }),
      },
      render: (data, record) => {
        let _tags = [
          <Tag color={EnumNoticeStatusColor[data]}>
            {EnumNoticeStatusDesc[data]}
          </Tag>,
        ];

        if (record.noticeStartDate && record.noticeEndDate) {
          if (isNoticeExpired(record)) {
            _tags.push(<Tag color="red">已过期</Tag>);
          }
        }

        return <Space.Compact>{_tags}</Space.Compact>;
      },
    },
    {
      title: "操作",
      render: (data, record) => {
        return (
          <>
            <Space.Compact size={"small"}>
              <Popconfirm
                title={
                  record.noticeStatus == 1
                    ? "确定要下线这条通知？"
                    : "确定要发布这条通知？"
                }
                onConfirm={() => _switchStatus(record)}
              >
                <Button
                  type="link"
                  size="small"
                  danger={record.noticeStatus == 1}
                  icon={
                    record.noticeStatus == 1 ? (
                      <RenderIcon type="PoweroffOutlined" />
                    ) : (
                      <RenderIcon type="SendOutlined" />
                    )
                  }
                  className="p-1"
                >
                  {record.noticeStatus === 1
                    ? "立即下线"
                    : record.noticeStatus == 2
                    ? "重新发布"
                    : "立即发布"}
                </Button>
              </Popconfirm>
              <Divider type="vertical" orientation="center" />
              <Button
                type="link"
                size="small"
                icon={<RenderIcon type="EditOutlined"></RenderIcon>}
                onClick={() => {
                  _edit(record);
                }}
              >
                编辑
              </Button>
              <Divider type="vertical" orientation="center" />
              <Popconfirm
                title={`确定要删除通知【${record.noticeTitle}】?`}
                onConfirm={() =>
                  useDelTableRecord("notice/DeleteNotice")(
                    {
                      id: record.noticeId,
                    },
                    table.reload
                  )
                }
              >
                <Button
                  type="link"
                  size="small"
                  icon={<RenderIcon type="DeleteOutlined"></RenderIcon>}
                  danger
                >
                  删除
                </Button>
              </Popconfirm>
            </Space.Compact>
          </>
        );
      },
    },
  ];

  const navigate = useNavigate();

  //新增|编辑圣人信息
  const _edit = (data?: any) => {
    navigate(
      data?.noticeId
        ? `/notice/edit_notice/${data?.noticeId}`
        : "/notice/add_notice"
    );
  };

  const _switchStatus = async (data?: any) => {
    const _res = await useDispatchAsync({
      type: "notice/ChangeStatus",
      data: {
        noticeId: data.noticeId,
        noticeStatus: data.noticeStatus == 1 ? 2 : 1,
      },
    });

    if (_res.code === ResponseCode.Success) {
      message.success("状态修改成功");
      table.reload();
      return;
    }

    message.error(_res.message);
  };

  return (
    <>
      <RemoteTable
        rowKey={"noticeId"}
        columns={_columns}
        table={table}
        loadData={useLoadTableData("notice/NoticeList")}
        scroll={{ x: true }}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <>
                {/* <Tooltip placement="topLeft" title={<div dangerouslySetInnerHTML={{ __html: record.noticeContent }} style={{ width: "auto", padding: "10px", maxWidth: "none" }}></div>}> */}
                <div
                  dangerouslySetInnerHTML={{ __html: record.noticeContent }}
                  style={{ lineHeight: "1.25" }}
                ></div>
                {/* </Tooltip> */}
              </>
            );
          },
        }}
        actionBar={
          <Button
            type="primary"
            icon={<RenderIcon type="PlusOutlined"></RenderIcon>}
            onClick={() => _edit()}
          >
            添加
          </Button>
        }
      ></RemoteTable>
      {/* <EditSaint ref={_ref} onSave={() => table.reload()}></EditSaint> */}
    </>
  );
};

export default Notice;
