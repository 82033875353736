import { Modal } from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import EditSaint from "@src/javascripts/pages/saints/saintList/edit";
import { ResponseCode } from "@src/global/layout";

const SaintModal = forwardRef((props: any, ref) => {
  const formRef = useRef<any>();
  const [open, setOpen] = useState(false);
  const { date, refreshData } = props;

  const saveCallback = (params) => {
    if (params.code == ResponseCode.Success) {
      setOpen(false);
      refreshData({ month: date.month() + 1, year: date.year() });
    }
  };
  useImperativeHandle(
    ref,
    () => {
      return {
        setOpen,
      };
    },
    []
  );

  useEffect(() => {
    if (!open) formRef.current?.resetFields?.();
    else {
      formRef.current.setFieldsValue({
        saintDate: date.format("YYYY/MM/DD"),
      });
    }
  }, [open]);

  return (
    <Modal
      open={open}
      title="添加圣人"
      width={720}
      onOk={async () => {
        console.log(formRef.current);
        await formRef.current?.validateFields?.();
        let _res = await formRef.current?.submit?.();
      }}
      onCancel={() => {
        setOpen(false);
      }}
    >
      <EditSaint
        ref={formRef}
        callback={saveCallback}
        showActionBar={false}
        formLayout={{
          layout: "vertical",
          labelCol: { span: 24 },
          wrapperCol: { span: 24 },
        }}
        wrapperProps={{
          style: {
            margin: 0,
            padding: "0 20px",
          },
        }}
        colProps={{
          md: 24,
          xxl: 24,
        }}
      />
    </Modal>
  );
});

export default SaintModal;
