import React, { useEffect, useRef, useState } from "react";
import { RemoteTable } from "@package/table";
import {
  useDelTableRecord,
  useLoadTableData,
} from "@src/javascripts/common/useTableCallback";
// import EditSaint from "./edit";
import { Button, Popconfirm, Space, message } from "antd";
import { RenderIcon } from "@package/icons";
import { useNavigate } from "react-router-dom";
import {
  useDispatchAsync,
  useLocalDispatch,
} from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";
import dayjs from "dayjs";

const Saints = () => {
  const _ref = useRef<any>();
  const [table] = RemoteTable.useTable();
  const [saintsTag, setSaintsTag] = useState<Array<any>>([] as any);

  useEffect(() => {
    useDispatchAsync({
      type: "saints/QueryAllSaintsTag",
      callback: (res) => {
        if (res.code === ResponseCode.Success) {
          setSaintsTag(res.data);
        }
      },
    });
  }, []);

  const _columns = [
    {
      dataIndex: "saintName",
      title: "圣人名称",
      filter: true,
    },
    {
      dataIndex: "saintDate",
      title: "时间【月/日】",
      filter: true,
      type: "daterange",
    },
    {
      dataIndex: "saintTags",
      title: "标签",
      type: "select",
      filter: false,
      formControlProps: {
        options: saintsTag,
        fieldNames: {
          label: "saintsTagName",
          value: "saintsTagId",
        },
      },
      render: (data) => {
        if (!data) return;

        let _tags = data.split(",");

        return (
          <Space direction="vertical">
            {_tags.map((item) => {
              return (
                saintsTag.find((sItem) => sItem.saintsTagId === parseInt(item))
                  ?.saintsTagName ?? data
              );
            })}
          </Space>
        );
      },
    },
    {
      title: "操作",
      fixed: "right",
      render: (data, record) => {
        return (
          <>
            <Space>
              <Button
                type="link"
                icon={<RenderIcon type="EditOutlined"></RenderIcon>}
                onClick={() => {
                  _edit(record);
                }}
              >
                编辑
              </Button>
              <Popconfirm
                title={`确定要删除x圣人【${record.saintName}】?`}
                onConfirm={() =>
                  useDelTableRecord("saints/DeleteSaint")(
                    {
                      id: record.saintId,
                    },
                    table.reload
                  )
                }
              >
                <Button
                  type="link"
                  icon={<RenderIcon type="DeleteOutlined"></RenderIcon>}
                  danger
                >
                  删除
                </Button>
              </Popconfirm>
            </Space>
          </>
        );
      },
    },
  ];

  const navigate = useNavigate();

  //新增|编辑圣人信息
  const _edit = (data?: any) => {
    navigate(
      data?.saintId
        ? `/saints/edit_saints/${data?.saintId}`
        : "/saints/add_saints"
    );
  };

  const retriveData = async () => {
    message.loading("开始数据修复");
    await useDispatchAsync({
      type: "saints/RetriveData",
    });
    message.success("数据修复完成");
  };

  return (
    <>
      <RemoteTable
        rowKey={"saintId"}
        columns={_columns}
        table={table}
        scroll={{ x: true }}
        loadData={useLoadTableData("saints/SaintList", (params) => {
          let { saintDate, ...restParams } = params;
          if (!!saintDate) {
            return {
              ...restParams,
              saintDate: [
                dayjs(params.saintDate[0]).format("YYYY/MM/DD 00:00:00"),
                dayjs(params.saintDate[1]).format("YYYY/MM/DD 23:59:59"),
              ],
            };
          }

          return restParams;
        })}
        actionBar={
          <>
            <Button
              type="primary"
              icon={<RenderIcon type="PlusOutlined"></RenderIcon>}
              onClick={() => _edit()}
            >
              添加
            </Button>
            {/* <Button onClick={() => retriveData()}>数据修复</Button> */}
          </>
        }
      ></RemoteTable>
      {/* <EditSaint ref={_ref} onSave={() => table.reload()}></EditSaint> */}
    </>
  );
};

export default Saints;
