export const EnumNoticeStatus = {
  Undeploy: 0,
  Deploy: 1,
  Offline: 2,
};

export const EnumNoticeStatusDesc = {
  0: "未发布",
  1: "已发布",
  2: "已下线",
};

export const EnumNoticeStatusColor = {
  0: "gray",
  1: "green",
  2: "red",
};
