import React from "react";
import { Divider, Tabs } from "antd";
import "./login.scss";
import AccountLogin from "./accountLogin";
import LeftIcon from "@src/images/left.png";
import Logo from "@src/images/logo.png";

const Login = () => {
  return (
    <div className="login-container">
      <div className="login-wrapper">
        <div className="login-head">
          <img
            src={Logo}
            alt=""
            style={{
              width: 40,
              height: 40,
              padding: 4,
              background: "#fff",
              borderRadius: "50%",
            }}
          />
          我存网后台管理系统
        </div>
        <div className="login-main">
          <div className="login-icon">
            <img src={LeftIcon} alt="" />
          </div>
          <div className="login-content">
            <h3
              style={{
                padding: "20px 20px 0",
                textAlign: "center",
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              账号登录
            </h3>
            <Divider />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "480px",
              }}
            >
              <AccountLogin />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
