import React, { useEffect, useImperativeHandle, useRef } from "react";
import { IFormItemProps, ModalForm, NormalForm } from "@package/form";
import {
  useDispatchAsync,
  useLocalDispatch,
} from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";
import { Col, Row, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { GetUploadUrl } from "@src/javascripts/common/uploadUtil";
import token from "@src/javascripts/common/storage/token";
import { EnumParish } from "@src/javascripts/common/enums/EnumParish";

const EditNotice = (prop, ref) => {
  const _ref = useRef<any>();
  const _navigate = useNavigate();

  const _param = useParams();

  useEffect(() => {
    if (_param?.id) {
      useLocalDispatch({
        type: "notice/FindNotice",
        data: {
          id: _param?.id,
        },
        callback: (res) => {
          if (res.code === ResponseCode.Success) {
            let { noticeStartDate, noticeEndDate, ...restData } = res.data;

            if (!restData.noticeAlways) {
              noticeStartDate = noticeEndDate ? dayjs(noticeStartDate) : null;
              noticeEndDate = noticeEndDate ? dayjs(noticeEndDate) : null;
            }

            _ref.current.setFieldsValue({
              ...restData,
              noticeEndDate,
              noticeStartDate,
            });

            return;
          }

          message.error(res.message);
        },
      });
    }
  }, []);

  const _formItems: Array<IFormItemProps> = [
    {
      formItemProps: {
        name: "noticeId",
        hidden: true,
      },
    },
    {
      formItemProps: {
        label: "公告名称",
        name: "noticeTitle",
      },
    },
    {
      type: "select",
      formItemProps: {
        label: "所属教区",
        name: "parish",
      },
      formControlProps: {
        options: Object.entries(EnumParish).map((item) => {
          return {
            value: item[0],
            label: item[1],
          };
        }),
      },
    },
    {
      type: "switch",
      formItemProps: {
        label: "长期有效",
        name: "noticeAlways",
        initialValue: false,
      },
    },
    {
      type: "date",
      formItemProps: {
        label: "生效时间",
        name: "noticeStartDate",
        rules: [{ required: true }],
        hidden: ({ noticeAlways }) => {
          return noticeAlways;
        },
      },
      formControlProps: {
        format: "YYYY年MM月DD日",
      },
    },
    {
      type: "date",
      formItemProps: {
        label: "过期时间",
        name: "noticeEndDate",
        rules: [{ required: true }],
        hidden: ({ noticeAlways }) => {
          return noticeAlways;
        },
      },
      formControlProps: {
        format: "YYYY年MM月DD日",
      },
    },
    {
      type: "switch",
      formItemProps: {
        label: "立即发布",
        name: "published",
        initialValue: false,
      },
    },
    {
      type: "editor",
      formItemProps: {
        label: "公告内容",
        name: "noticeContent",
        rules: [{ required: true }],
      },
      formControlProps: {
        // uploadUrl: GetUploadUrl(),
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: GetUploadUrl({
            editor: true,
          }),

          // Enable the XMLHttpRequest.withCredentials property.
          // withCredentials: true,

          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            "X-CSRF-TOKEN": "CSRF-Token",
            authorization: token.get(),
          },
        },
      },
    },
  ];

  const saveData = async (data) => {
    console.log(data, "--notice");
    const _res = await useDispatchAsync({
      type: data.noticeId ? "notice/UpdateNotice" : "notice/AddNotice",
      data: {
        ...data,
        // saintDate: dayjs(data.saintDate).format("MM月DD日"),
      },
    });

    if (_res.code === ResponseCode.Success) {
      message.success(`信息编辑成功`);
      _navigate("/notice/list");
      return;
    }

    return _res.message;
  };

  useImperativeHandle(ref, () => _ref.current, []);

  return (
    <Row style={{ display: "flex", justifyContent: "center" }}>
      <Col xs={24} xxl={16} md={18}>
        <div
          style={{
            background: "#fff",
            borderRadius: "6px",
            padding: "40px 20px",
            marginBottom: "60px",
          }}
        >
          <NormalForm
            ref={_ref}
            // title="添加/编辑圣人信息"
            formItems={_formItems}
            onSave={saveData}
            labelCol={{ xs: { span: 24 }, sm: { span: 6 }, xxl: { span: 2 } }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 18 },
              xxl: { span: 22 },
            }}
            cancelButtonText="返回"
            actionButtonsPosition={"fixed"}
            onCancel={() => {
              _navigate("/notice/list");
            }}
          ></NormalForm>
        </div>
      </Col>
    </Row>
  );
};

export default React.forwardRef(EditNotice);
