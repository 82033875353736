import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { IFormItemProps, ModalForm, NormalForm } from "@package/form";
import {
  useDispatchAsync,
  useLocalDispatch,
} from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";
import { Col, Row, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";

const EditSaint = (prop, ref) => {
  const _ref = useRef<any>();
  const _navigate = useNavigate();
  const {
    formLayout = {},
    showActionBar,
    colProps = {},
    wrapperProps = {},
    callback,
  } = prop;

  const _param = useParams();
  const [saintsTag, setSaintsTag] = useState<Array<any>>([] as any);

  useEffect(() => {
    if (_param?.id) {
      useLocalDispatch({
        type: "saints/FindSaint",
        data: {
          id: _param?.id,
        },
        callback: (res) => {
          if (res.code === ResponseCode.Success) {
            const { saintDate, saintTags, ...restData } = res.data;
            _ref.current.setFieldsValue({
              ...restData,
              saintDate: saintDate
                ? dayjs(dayjs().year() + "/" + saintDate)
                : null,
              saintTags: !!saintTags
                ? saintTags.split(",").map((item) => Number(item))
                : [],
            });

            return;
          }

          message.error(res.message);
        },
      });
    }

    useDispatchAsync({
      type: "saints/QueryAllSaintsTag",
      callback: (res) => {
        if (res.code === ResponseCode.Success) {
          setSaintsTag(res.data);
        }
      },
    });
  }, []);

  const _formItems: Array<IFormItemProps> = [
    {
      formItemProps: {
        name: "saintId",
        hidden: true,
      },
    },
    {
      formItemProps: {
        label: "圣人名称",
        name: "saintName",
        rules: [{ required: true }],
      },
    },
    {
      type: "date",
      formItemProps: {
        label: "日期",
        name: "saintDate",
        rules: [{ required: true }],
      },
      formControlProps: {
        format: "MM月DD日",
      },
    },
    {
      type: "select",
      formItemProps: {
        label: "标签",
        name: "saintTags",
      },
      formControlProps: {
        mode: "multiple",
        options: saintsTag,
        fieldNames: {
          label: "saintsTagName",
          value: "saintsTagId",
        },
      },
    },
    {
      type: "editor",
      formItemProps: {
        label: "圣人简介",
        name: "saintContent",
        rules: [{ required: true }],
      },
    },
  ];

  const saveData = async (data) => {
    const _res = await useDispatchAsync({
      type: data.saintId ? "saints/UpdateSaint" : "saints/AddSaint",
      data: {
        ...data,
        saintDate: dayjs(data.saintDate).format("MM/DD"),
      },
    });

    if (callback) {
      return callback?.(_res);
    }
    if (_res.code === ResponseCode.Success) {
      message.success(`信息编辑成功`);

      _navigate("/saints/list");
      return;
    }

    return _res.message;
  };

  useImperativeHandle(ref, () => _ref.current, []);

  return (
    <Row style={{ display: "flex", justifyContent: "center" }}>
      <Col xs={24} xxl={16} md={18} {...colProps}>
        <div
          style={{
            background: "#fff",
            borderRadius: "6px",
            padding: "40px 20px",
            marginBottom: "60px",
          }}
          {...wrapperProps}
        >
          <NormalForm
            ref={_ref}
            // title="添加/编辑圣人信息"
            formItems={_formItems}
            onSave={saveData}
            labelCol={{ xs: { span: 24 }, sm: { span: 6 }, xxl: { span: 2 } }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 18 },
              xxl: { span: 22 },
            }}
            cancelButtonText="返回"
            actionButtonsPosition={"fixed"}
            showActionBar={
              typeof showActionBar == "undefined" ? true : showActionBar
            }
            onCancel={() => {
              _navigate("/saints/list");
            }}
            {...formLayout}
          ></NormalForm>
        </div>
      </Col>
    </Row>
  );
};

export default React.forwardRef(EditSaint);
