import { Button, Form, Popconfirm } from "antd";
import RenderInlineFormItem from "../FormItemInline";
import React from "react";
import { IFormItemProps } from "../../../../types";
import cloneDeep from "../../../util/cloneDeep";
import "./index.scss"

const RenderFormTableListItem = (props: IFormItemProps) => {
  let { children = [], formListItemActionButtons, min, max } = props;
  if (children?.length === 0) {
    return <></>;
  }

  let {
    hidden = false,
    name = "tablelist",
    shouldUpdate,
    rules = [],
    label = "",
    noStyle = false,
    disabled = false,
    editable = true,
    removable = true,
    validateTrigger = ["onChange"],
    defaultValue = {},
    ...restProps
  } = props.formItemProps || {};

  if (typeof hidden === "function") {
    shouldUpdate = typeof shouldUpdate === "undefined" ? true : shouldUpdate;
  }

  if (children.length === 0) {
    console.warn(`${label}children参数没配置`);
  }

  const form = Form.useFormInstance();

  const isControlHidden = () => {
    let _isHide = hidden;

    if (typeof hidden === "function") _isHide = hidden?.(form.getFieldsValue());

    return _isHide;
  };

  const _filterChildren = (index?: any) => {
    return cloneDeep(children, 'array')
      .filter((item) => {
        const { hidden } = item.formItemProps ?? {};

        if (!hidden) return true;

        if (typeof (hidden) === "boolean") return !hidden

        return !hidden?.(form.getFieldsValue(), index ?? 0)
      })
  }

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prev, current, info) =>
        typeof shouldUpdate === "function"
          ? shouldUpdate(prev, current, info)
          : shouldUpdate
      }
      validateTrigger={validateTrigger}
    >
      {(formProps) => {
        // console.log(defaultValue, 'default value')
        return (
          !isControlHidden() && (
            <Form.Item
              label={label}
              {...restProps}
            >
              <div>
                <table className="ant-table form-table-list" style={{ width: "100%", margin: "0px auto" }}>
                  <thead className="ant-table-thead">
                    <tr className="ant-table-cell">
                      {_filterChildren().map(item => item.formItemProps.label).map((item: string, index) => {
                        return <th key={`th-${index}`}>{item}</th>;
                      })}
                      {(removable || !!formListItemActionButtons) && <th className="ant-table-cell">操作</th>}
                    </tr>
                  </thead>
                  <tbody className="ant-table-tbody">
                    <Form.List name={name} {...restProps}>
                      {(fields, { add, remove }) => {
                        return (
                          <>
                            {fields.map(
                              (
                                { key, name: fieldName, ...restField },
                                itemIndex
                              ) => (
                                <tr key={key}>
                                  {_filterChildren()
                                    .map(
                                      (child: IFormItemProps, index: number) => {
                                        let _child = Object.assign({}, child);

                                        const _name = _child["formItemProps"][
                                          "name"
                                        ] as string | number;

                                        _child.formItemProps = {
                                          ...restField,
                                          ...child.formItemProps,
                                          name: [fieldName, _name],
                                          shouldUpdate: () => !!props?.isDisabled
                                        };

                                        const isDisabled = (
                                          disabledParam,
                                          ...params
                                        ) => {
                                          if (
                                            typeof disabledParam === "function"
                                          ) {
                                            return disabledParam(params);
                                          }

                                          return !!disabledParam;
                                        };

                                        _child.formControlProps = {
                                          ...(child.formControlProps || {}),
                                          disabled: isDisabled(
                                            child.formControlProps?.disabled,
                                            child,
                                            index,
                                            itemIndex,
                                            form.getFieldValue(name)?.[
                                            itemIndex
                                            ]?.[_name],
                                            form.getFieldValue(name)?.[itemIndex]
                                          ),
                                        };

                                        return (
                                          <td
                                            className="ant-table-cell"
                                            key={`list-form-item-${index}`}
                                          >
                                            <RenderInlineFormItem
                                              {..._child}
                                              index={index}
                                            />
                                          </td>
                                        );
                                      }
                                    )}
                                  <td className="ant-table-cell">
                                    {((!removable &&
                                      typeof removable === "function" &&
                                      !removable?.({
                                        ...props,
                                        type: "operator",
                                        itemIndex,
                                        data: form.getFieldValue(name)?.[
                                          itemIndex
                                        ],
                                      })) ||
                                      removable) && (typeof (min) === "undefined" || fields.length > min) && (
                                        <Popconfirm
                                          title="确定移除该条记录？"
                                          onConfirm={() => remove(fieldName)}
                                        >
                                          <Button
                                            type="dashed"
                                            className="danger"
                                            key={`list-item-remove-${props.index}`}
                                            icon={<>&times;</>}
                                          >
                                            {props.removeComponent ? (
                                              <>{props.removeComponent}</>
                                            ) : (
                                              <>移除 </>
                                            )}
                                          </Button>
                                        </Popconfirm>
                                      )}
                                    {
                                      formListItemActionButtons?.(itemIndex, form.getFieldValue(name)?.[itemIndex])
                                    }
                                  </td>
                                </tr>
                              )
                            )}

                            {!disabled && editable && (typeof (max) === "undefined" || max > fields.length) && (
                              <tr className="form-item-action-row">
                                <td
                                  className="ant-table-cell"
                                  colSpan={_filterChildren().length + 1}
                                  style={{padding:"4px 0"}}
                                >
                                  <Button
                                    icon={<>+</>}
                                    type="dashed"
                                    style={{padding:"8px 0"}}
                                    onClick={() => add(defaultValue)}
                                  >
                                    添加
                                  </Button>
                                </td>
                              </tr>
                            )}
                          </>
                        );
                      }}
                    </Form.List>
                  </tbody>
                </table>
              </div>
            </Form.Item>
          )
        );
      }}
    </Form.Item>
  );
};

export default RenderFormTableListItem;
