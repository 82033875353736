import React, { useEffect, useRef } from "react";
import EditEvent from "./editEvent";
import { useDispatchAsync } from "@src/javascripts/common/useDispatch";
import { RemoteTable } from "@package/table";
import { Button, Popconfirm, Space, Tag, Tooltip, Upload } from "antd";
import { RenderIcon } from "@package/icons";
import {
  useDelTableRecord,
  useLoadTableData,
} from "@src/javascripts/common/useTableCallback";
import EnumScheduleType from "./enum/EnumScheduleType";
import dayjs from "dayjs";
import EnumScheduleItemType from "./enum/EnumScheduleItemType";
import { GetUploadProps } from "@src/javascripts/common/uploadUtil";

const DailySchedule = () => {
  const _eventEditorModal = useRef<any>();
  const [table] = RemoteTable.useTable();

  const _columns = [
    // {
    //   dataIndex: "scheduleType",
    //   title: "事件类型",
    //   render: (data) => {
    //     return (
    //       EnumScheduleType.find((item) => item.value === data)?.label ?? data
    //     );
    //   },
    // },
    {
      dataIndex: "scheduleDate",
      title: "时间日期",
      render: (data, record) => {
        if (record.scheduleType === "range")
          return (
            <>
              {dayjs(record.scheduleStart).format("YYYY年MM月DD日")} -{" "}
              {dayjs(record.scheduleEnd).format("YYYY年MM月DD日")}
            </>
          );
        return dayjs(data).format("YYYY年MM月DD日");
      },
    },
    {
      dataIndex: "scheduleItems",
      title: "事件信息",
      ellipsis: {
        showTitle: false,
      },
      render: (data) => {
        return (
          <Space direction="vertical">
            {data.map((item, index) => {
              return (
                <div key={`schedule-item-${item.scheduleId}-${index}`}>
                  <Tag>
                    {EnumScheduleItemType.find(
                      (subItem) => subItem.value === item.scheduleItemTitle
                    )?.label ?? item.scheduleItemTitle}
                  </Tag>
                  <Tooltip title={item.scheduleItemContent}>
                    {item.scheduleItemContent.substr(0, 10)}
                  </Tooltip>
                </div>
              );
            })}
          </Space>
        );
      },
    },
    {
      title: "操作",
      fixed: "right",
      render: (data, record) => {
        return (
          <Space>
            <Button
              type="link"
              icon={<RenderIcon type="EditOutlined"></RenderIcon>}
              onClick={() => _editEvent(record)}
            >
              编辑
            </Button>
            <Popconfirm
              title={"确定要删除这条信息？"}
              onConfirm={() =>
                useDelTableRecord("daily_schedule/DeleteSchedule")(
                  {
                    scheduleId: record.scheduleId,
                  },
                  (res) => {
                    table.reload();
                  }
                )
              }
            >
              <Button
                type="link"
                danger
                icon={<RenderIcon type="DeleteOutlined"></RenderIcon>}
              >
                删除
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ] as any[];

  const _editEvent = (data?: any) => {
    _eventEditorModal.current.setVisible(true);

    if (data) {
      const { scheduleDate, scheduleStart, scheduleEnd, ...restData } = data;

      let _data = { ...restData };

      if (!!scheduleDate) {
        _data["scheduleDate"] = dayjs(scheduleDate);
      } else if (scheduleStart || scheduleEnd) {
        _data["scheduleDateRange"] = [dayjs(scheduleStart), dayjs(scheduleEnd)];
      }
      _eventEditorModal.current.setFieldsValue(_data);
    }
  };

  return (
    <>
      <RemoteTable
        scroll={{ x: true }}
        rowKey={"scheduleId"}
        table={table}
        columns={_columns}
        loadData={useLoadTableData("daily_schedule/ScheduleList")}
        actionBar={
          <>
            <Button
              onClick={() => _editEvent()}
              type="primary"
              icon={<RenderIcon type="PlusOutlined"></RenderIcon>}
            >
              添加
            </Button>
            {/* <Upload
              {...GetUploadProps({
                action: "/backapi/upload/read_pdf",
              })}
            >
              读取文件
            </Upload> */}
          </>
        }
      ></RemoteTable>
      <EditEvent
        ref={_eventEditorModal}
        onSave={() => table.reload()}
      ></EditEvent>
    </>
  );
};

export default DailySchedule;
